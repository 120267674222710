<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">
          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
            <el-form-item :prop="'name.'+language" :rules="rules['field']">
              <el-input v-model="objData.name[language]" :placeholder="`Name (${language})`"/>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Description ({{ language }})</label>
            <el-form-item :prop="'description.'+language" :rules="rules['field']">
              <el-input type="textarea" v-model="objData.description[language]" :placeholder="`Description (${language})`"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Discount Type</label>
            <el-form-item prop="discount_type" :rules="rules['field']">
              <el-select class="select-display-block" clearable v-model="objData.discount_type">
                <el-option v-for="type in discountTypes"
                           :label="type.label"
                           :value="type.value"
                           :key="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Discount Value</label>
            <el-form-item prop="discount_value" :rules="rules['field']">
              <el-input v-model="objData.discount_value" placeholder="Discount Value"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Usage Count</label>
            <el-form-item prop="usage_num" :rules="rules['field']">
              <el-input v-model="objData.usage_num" placeholder="Usage Num"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Coupon Code</label>
            <el-form-item prop="coupon_code" :rules="rules['field']">
              <el-input v-model="objData.coupon_code" placeholder="Coupon Code"></el-input>
            </el-form-item>
          </div>

        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Category</label>
            <el-form-item prop="category_ids" >
              <el-select multiple v-model="objData.category_ids" class="select-display-block">
                <el-option v-for="category in categories" :label="category.name" :value="category.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Services</label>
            <el-select
                v-model="objData.service_ids" class="select-display-block" multiple clearable filterable remote reserve-keyword placeholder="Search for services" :remote-method="getRemoteServices" :loading="remoteServicesLoading">
              <el-option
                  v-for="item in services"
                  :label="item.name"
                  :key="item.id"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Start & End Date</label>
            <el-form-item  prop="date" :rules="rules['promo_date']">
              <el-date-picker
                  class="full-width"
                  v-model="objData.date"
                  type="datetimerange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  value-format="YYYY-MM-DD hh:mm:ss"
                  :disabled-date="disabledDate"
              />
            </el-form-item>
          </div>

          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active"/>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs,watch} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import {getServices} from "@/core/helpers/remote_data";
export default defineComponent({
  name: "coupon-comp",
  components: {ImageComp},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref(['name','description','category_ids','service_ids']);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);
    const discountTypes = ref([]);
    const categories = ref([]);
    const services = ref([]);
    const remoteServicesLoading = ref(false);

    objData.value = {
      name: {},
      description : {},
      code : '',
      discount_type : '',
      discount_value : '',
      coupon_code : '',
      date : '',
      usage_num : '',
      category_ids : [],
      service_ids : [],
      start_at : '',
      end_at : '',
      is_active : true
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      promo_date: [
        {
          required: true,
          message: "This field is required",
          trigger: "blur",
        },
      ],
    });
    const disabledDate = (time) => {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date.getTime()
    }
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.COUPON.create);
            languages.value     = response.data.data.languages;
            discountTypes.value = response.data.data.discount_types;
            categories.value = response.data.data.categories;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.COUPON.edit(route.params.id));
            objData.value       = response.data.data.coupon;
            languages.value     = response.data.data.languages;
            discountTypes.value = response.data.data.discount_types;
            categories.value    = response.data.data.categories;
            services.value      = response.data.data.services;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.COUPON.show(route.params.id));
            objData.value       = response.data.data.coupon;
            languages.value     = response.data.data.languages;
            discountTypes.value = response.data.data.discount_types;
            categories.value    = response.data.data.categories;
            services.value      = response.data.data.services;

          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      objData.value.start_at     = objData.value.date[0];
      objData.value.end_at     = objData.value.date[1];
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.COUPON.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'coupons-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      objData.value.start_at   = objData.value.date[0];
      objData.value.end_at     = objData.value.date[1];
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      formData.append('_method' , 'put');
      ApiAxiosService.post(APIs.COUPON.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'coupons-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }

    const getRemoteServices = async (query) => {

      if (query) {
        remoteServicesLoading.value = true
        services.value = await getServices(query);
        console.log(services.value);
        remoteServicesLoading.value = false;
      } else {
        services.value = [];
      }
    }
    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      isIndeterminate,
      languages,
      discountTypes,
      categories,
      services,
      getRemoteServices,
      remoteServicesLoading,
      disabledDate
    };
  },

});
</script>

